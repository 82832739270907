import { SearchOutlined, UserAddOutlined } from '@ant-design/icons';
import { Button, Input, Popconfirm, PopconfirmProps, Table, TableProps, Tag, message } from 'antd';
import React, { useState } from 'react';

interface DataType {
  key: string;
  id: string;
  name: string;
  grade: string;
  contact: string;
  assignedUser: number;
  assignedNetwork: number;
}

const confirm: PopconfirmProps['onConfirm'] = (e) => {
  message.success('회사 정보를 삭제했습니다');
};

const cancel: PopconfirmProps['onCancel'] = (e) => {
  message.error('취소되었습니다');
};

const SalesInfoPage = () => {

  const columns: TableProps<DataType>['columns'] = [
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      width: 120,
      align: 'center',
      render: (text) => <a onClick={() => showModal()}>{text}</a> 
    },
    {
      title: '등급',
      dataIndex: 'grade',
      key: 'grade',
      render: (text) => <Tag color={text === 'white' ? '' : text === 'yellow' ? 'gold' : text} style={{margin: 0}}>{text}</Tag>,
      width: 96,
      align: 'center'
    },
    {
      title: '할당된 유저',
      dataIndex: 'assignedUser',
      key: 'assignedUser',
      width: 120,
      align: 'center'
    },
    {
      title: '할당된 네트워크',
      dataIndex: 'assignedNetwork',
      key: 'assignedNetwork',
      width: 200,
      align: 'center'
    },
    {
      title: '연락처',
      dataIndex: 'contact',
      key: 'contact',
      width: 200,
      align: 'center'
    },
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
      width: 160,
      align: 'center'
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Popconfirm
          title="영업사원 삭제"
          description="영업사원 계정을 삭제하시겠습니까?"
          onConfirm={confirm}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <a>Delete</a>
        </Popconfirm>
      ),
      align: 'center'
    },
  ];

  const data: DataType[] = [
    {
      key: '1',
      id: 'asdf1234',
      name: '홍길동',
      grade: 'yellow',
      contact: '010-1234-1234',
      assignedUser: 4,
      assignedNetwork: 12,
    },
    {
      key: '2',
      id: 'asdf1234',
      name: '홍길동',
      grade: 'white',
      contact: '010-1234-1234',
      assignedUser: 4,
      assignedNetwork: 12,
    },
    {
      key: '3',
      id: 'asdf1234',
      name: '홍길동',
      grade: 'red',
      contact: '010-1234-1234',
      assignedUser: 4,
      assignedNetwork: 12,
    },
    {
      key: '4',
      id: 'asdf1234',
      name: '홍길동',
      grade: 'blue',
      contact: '010-1234-1234',
      assignedUser: 4,
      assignedNetwork: 12,
    },
    {
      key: '5',
      id: 'asdf1234',
      name: '홍길동',
      grade: 'purple',
      contact: '010-1234-1234',
      assignedUser: 4,
      assignedNetwork: 12,
    },
    {
      key: '6',
      id: 'asdf1234',
      name: '홍길동',
      grade: 'black',
      contact: '010-1234-1234',
      assignedUser: 4,
      assignedNetwork: 12,
    },
    {
      key: '7',
      id: 'asdf1234',
      name: '홍길동',
      grade: 'silver',
      contact: '010-1234-1234',
      assignedUser: 4,
      assignedNetwork: 12,
    },
    {
      key: '8',
      id: 'asdf1234',
      name: '홍길동',
      grade: 'yellow',
      contact: '010-1234-1234',
      assignedUser: 4,
      assignedNetwork: 12,
    },
    {
      key: '9',
      id: 'asdf1234',
      name: '홍길동',
      grade: 'yellow',
      contact: '010-1234-1234',
      assignedUser: 4,
      assignedNetwork: 12,
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <div className='page-wrap'>
      <div className='page-container'>
        <div className='page-title'>영업사원 정보</div>
        <div className='page-top-menu-wrap'>
          <div className='page-filter-wrap'>
            <Input placeholder="영업사원 검색" prefix={<SearchOutlined />} style={{width: 300}} />
            <Button type="primary" icon={<SearchOutlined />}>
              검색
            </Button>
          </div>
          <Button type="primary" icon={<UserAddOutlined />} onClick={() => setIsModalOpen(true)}>
            영업사원 추가
          </Button>
        </div>
        <Table columns={columns} dataSource={data} bordered />
      </div>
    </div>
  );
};

export default SalesInfoPage;