import { getApp } from "firebase/app";
import { doc, getFirestore, updateDoc } from "firebase/firestore";

const uploadContract = async (taskId: string, files: any) => {
  await updateDoc(doc(getFirestore(getApp()), 'kr_user_tasks', taskId), {
    subStep: 3,
    forIfa: false,
    contractFile: files,
  });
};

export default uploadContract;
