import { KeyOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import routes from '../configs/routes';
import { getApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

interface Props{
  setIsLogined: Dispatch<SetStateAction<boolean>>
  userType: string;
}

const LoginPage:React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const login = () => {
    if (props.userType === 'admin') {
      props.setIsLogined(true);
      navigate(routes.companyInfo);
    } else if (props.userType === 'ifa') {
      props.setIsLogined(true);
      navigate(routes.ifa);
    }
  };

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    if (email === '' || password === '') {
      return;
    }

    const auth = getAuth(getApp());

    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      console.log(err);
      alert('잘못된 계정정보입니다');
    }
  };

  const handleEnter = (e: any) => {
    if (e.keyCode === '13') {
      handleLogin();
    }
  };

  return (
    <div className='login-page-frame'>
      <div className='login-page-wrap'>
        <img className='login-logo' src='/img/logo-app.png' alt='logo' />
        <div className='login-page-inputs-wrap'>
          <Input size="large" type='text' placeholder="email" prefix={<UserOutlined />} onKeyDown={handleEnter} value={email} onChange={(e) => setEmail(e.target.value)} />
          <Input size="large" type='password' placeholder="password" prefix={<KeyOutlined />} onKeyDown={handleEnter} value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <Button style={{width: '100%'}} size="large" type='primary' onClick={handleLogin}>Login</Button>
      </div>
    </div>
  );
};

export default LoginPage;