import { FileAddOutlined, FileSearchOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, DatePickerProps, Input, Modal, Popconfirm, PopconfirmProps, Radio, RadioChangeEvent, Select, Space, Table, TableProps, message } from 'antd';
import React, { useState } from 'react';

interface DataType {
  key: string;
  logo: string;
  companyName: string;
  productName: string;
  lastUpdate: string;
}

const { TextArea } = Input;

const confirm: PopconfirmProps['onConfirm'] = (e) => {
  message.success('상품을 삭제했습니다');
};

const cancel: PopconfirmProps['onCancel'] = (e) => {
  message.error('취소되었습니다');
};

const ProductInfoPage = () => {

  const columns: TableProps<DataType>['columns'] = [
    {
      title: '로고',
      dataIndex: 'logo',
      key: 'logo',
      width: 72,
      render: (text) => <img className='table-logo' src={text} alt='logo' />,
      align: 'center'
    },
    {
      title: '회사명',
      dataIndex: 'companyName',
      key: 'companyName',
      width: 160,
      align: 'center'
    },
    {
      title: '상품명',
      dataIndex: 'productName',
      key: 'productName',
      render: (text) => <a onClick={showModal}>{text}</a>,
    },
    {
      title: '마지막 업데이트',
      dataIndex: 'lastUpdate',
      key: 'lastUpdate',
      width: 200,
      align: 'center'
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Popconfirm
          title="상품 삭제"
          description="해당 상품을 삭제하시겠습니까?"
          onConfirm={confirm}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <a>Delete</a>
        </Popconfirm>
      ),
      width: 120,
      align: 'center'
    },
  ];

  const data: DataType[] = [
    {
      key: '1',
      logo: '/img/logo-axa.png',
      companyName: 'AXA',
      productName: 'Wealth Advance Savings Series II - Ultimate',
      lastUpdate: '2024-04-29 14:00:00',
    },
    {
      key: '2',
      logo: '/img/logo-zurich.png',
      companyName: 'Zurich',
      productName: 'Wealth Advance Savings Series II - Ultimate',
      lastUpdate: '2024-04-29 14:00:00',
    },
    {
      key: '3',
      logo: '/img/logo-generali.png',
      companyName: 'Generali',
      productName: 'Wealth Advance Savings Series II - Ultimate',
      lastUpdate: '2024-04-29 14:00:00',
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [imgLoading, setImgLoading] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      setIsModalOpen(false);
    }, 2000);

  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [value, setValue] = useState('');

  const selectHandleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const dateOnChange: DatePickerProps['onChange'] = (date, dateString) => {
    console.log(date, dateString);
  };

  // 양도여부
  const [transfer, setTransfer] = useState('able');

  return (
    <div className='page-wrap'>
      <Modal
        title="상품정보"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={640}
        footer={[
          <Button key="back" onClick={handleCancel}>
            취소
          </Button>,
          <Button type="primary" loading={loading} onClick={handleOk}>
            저장
          </Button>
        ]}
      >
        <div className='modal-body'>
          <div className='input-label'>상품분류</div>
          <Select
            defaultValue="상품분류 선택"
            style={{ width: 120 }}
            onChange={selectHandleChange}
            options={[
              { value: '0', label: '배당저축보험' },
              { value: '1', label: '무배당보험' },
            ]}
          />
          <div className='input-label'>출시일</div>
          <DatePicker onChange={dateOnChange} placeholder='출시일 선택' />
          <div className='input-label'>양도 여부</div>
          <Radio.Group
            options={[
              { label: '가능', value: 'able' },
              { label: '불가능', value: 'disable' },
            ]}
            onChange={({ target: { value } }: RadioChangeEvent) => setTransfer(value)}
            value={transfer}
            optionType="button"
          />
          <div className='input-label'>판매사 제공 정보 URL</div>
          <Input placeholder="판매사 제공 정보 URL" />
          <div className='input-label'>납부 기간</div>
          <Input placeholder="납부 기간" />
          <div className='input-label'>월 납입액(단위)</div>
          <Input placeholder="월 납입액" type='number' />
          <div className='input-label'>해지 규정</div>
          <TextArea
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="해지 규정"
            autoSize={{ minRows: 3, maxRows: 10 }}
          />
          <div className='input-label'>연이율(%)</div>
          <Input placeholder="연이율" type='number' />
          <div className='input-label'>만기수익(%)</div>
          <Input placeholder="만기수익" type='number' />
          <div className='input-label'>납입 및 수익 상세 URL</div>
          <Input placeholder="납입 및 수익 상세 URL" />
        </div>
        <div className='divider' />
      </Modal>
      <div className='page-container'>
        <div className='page-title'>상품정보</div>
        <div className='page-top-menu-wrap'>
          <div className='page-filter-wrap'>
            <Select
              defaultValue="all"
              style={{ width: 120 }}
              onChange={selectHandleChange}
              options={[
                { value: 'all', label: '회사 전체' },
                { value: 'axa', label: 'axa' },
                { value: 'zurich', label: 'zurich' },
                { value: 'generali', label: 'generali'},
              ]}
            />
            <Input placeholder="상품 검색" prefix={<FileSearchOutlined />} style={{width: 300}} />
            <Button type="primary" icon={<SearchOutlined />}>
              검색
            </Button>
          </div>
          <Button type="primary" icon={<FileAddOutlined />} onClick={() => setIsModalOpen(true)}>
            상품추가
          </Button>
        </div>
        <Table columns={columns} dataSource={data} bordered />
      </div>
    </div>
  );
};

export default ProductInfoPage;