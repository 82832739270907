import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import routes from '../configs/routes';
import { getAuth, signOut } from 'firebase/auth';
import { getApp } from 'firebase/app';

const IFANav = () => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    signOut(getAuth(getApp()));

    setIsModalOpen(false);
    navigate(routes.default);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className='nav-ifa'>
      <div>
        <img src="/img/logo-app.png" alt="logo" style={{width: "28px", marginLeft: "8px"}} />
      </div>
      <Modal
        title="로그아웃"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            취소
          </Button>,
          <Button key="submit" type="primary" danger onClick={handleOk}>
            로그아웃
          </Button>,]}
      >
        <p>로그아웃 하시겠습니까?</p>
      </Modal>
      <Button danger type='text' onClick={() => showModal()}>로그아웃</Button>
    </div>
  );
};

export default IFANav;