const splitPhoneNumber = (dialNumber: string) => {
  let d = dialNumber;

  if (d.includes('+8210')) {
    d = d.replaceAll('+8210', '010');
  } else if (d.includes('+82')) {
    d = d.replaceAll('+82', '');
  }

  if (d.length === 8) {
    return `${d.substring(0, 4)}-${d.substring(4)}`;
  }

  if (d.length === 10) {
    if (d.substring(0, 2) === '02') {
      return `${d.substring(0, 2)}-${d.substring(2, 6)}-${d.substring(6)}`;
    }

    return `${d.substring(0, 3)}-${d.substring(3, 6)}-${d.substring(6)}`;
  }

  if (d.length === 11) {
    return `${d.substring(0, 3)}-${d.substring(3, 7)}-${d.substring(7)}`;
  }

  return d;
};

export default splitPhoneNumber;
