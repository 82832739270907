import { initializeApp, getApps } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const initFirebase = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyAICO2YQ5lUi30Z52UXtF3d8LPHumKm7q8",
    authDomain: "insureinapp.firebaseapp.com",
    projectId: "insureinapp",
    storageBucket: "insureinapp.appspot.com",
    messagingSenderId: "554766735240",
    appId: "1:554766735240:web:ae3b36a0db13dfa0110c3d",
    measurementId: "G-HGWPF3YJ8H"
  };
  
  // Initialize Firebase
  const apps = getApps();
  if (apps.length === 0) {
    const app = initializeApp(firebaseConfig);

    initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider('6Lc_kY4qAAAAAFdHJWE8MFnklD7w_2uVdFlmPO8S'),
      isTokenAutoRefreshEnabled: true
    });
  }
};

export default initFirebase;
