import { BankOutlined, LoadingOutlined, PlusCircleOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, GetProp, Input, Modal, Popconfirm, PopconfirmProps, Table, TableProps, Upload, UploadProps, message } from 'antd';
import React, { useState } from 'react';

interface DataType {
  key: string;
  logo: string;
  name: string;
  lastUpdate: string;
}

const { TextArea } = Input;

const confirm: PopconfirmProps['onConfirm'] = (e) => {
  message.success('회사 정보를 삭제했습니다');
};

const cancel: PopconfirmProps['onCancel'] = (e) => {
  message.error('취소되었습니다');
};

const CompanyInfoPage = () => {

  const columns: TableProps<DataType>['columns'] = [
    {
      title: '로고',
      dataIndex: 'logo',
      key: 'logo',
      width: 72,
      render: (text) => <img className='table-logo' src={text} alt='logo' />,
      align: 'center'
    },
    {
      title: '회사명',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <a onClick={showModal}>{text}</a>,
    },
    {
      title: '마지막 업데이트',
      dataIndex: 'lastUpdate',
      key: 'lastUpdate',
      width: 200,
      align: 'center'
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Popconfirm
          title="회사정보 삭제"
          description="해당 회사의 정보를 삭제하시겠습니까?"
          onConfirm={confirm}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <a>Delete</a>
        </Popconfirm>
      ),
      width: 120,
      align: 'center'
    },
  ];

  const data: DataType[] = [
    {
      key: '1',
      logo: '/img/logo-axa.png',
      name: 'AXA',
      lastUpdate: '2024-04-29 14:00:00',
    },
    {
      key: '2',
      logo: '/img/logo-zurich.png',
      name: 'Zurich',
      lastUpdate: '2024-04-29 14:00:00',
    },
    {
      key: '3',
      logo: '/img/logo-generali.png',
      name: 'Generali',
      lastUpdate: '2024-04-29 14:00:00',
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [imgLoading, setImgLoading] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      setIsModalOpen(false);
    }, 2000);

  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [value, setValue] = useState('');

  // img upload
  type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

  const getBase64 = (img: FileType, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: FileType) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const [imageUrl, setImageUrl] = useState<string>();

  const imgHandleChange: UploadProps['onChange'] = (info) => {
    if (info.file.status === 'uploading') {
      setImgLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as FileType, (url) => {
        setImgLoading(false);
        setImageUrl(url);
      });
    }
  };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {imgLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <div className='page-wrap'>
      <Modal
        title="회사정보"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            취소
          </Button>,
          <Button type="primary" loading={loading} onClick={handleOk}>
            저장
          </Button>
        ]}
      >
        <div className='modal-body'>
          <div className='input-label'>로고</div>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
            beforeUpload={beforeUpload}
            onChange={imgHandleChange}
          >
            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
          </Upload>
          <div className='input-label'>회사명</div>
          <Input placeholder="회사명" />
          <div className='input-label'>홈페이지 URL</div>
          <Input placeholder="홈페이지 URL" />
          <div className='input-label'>소속국가</div>
          <Input placeholder="소속국가" />
          <div className='input-label'>시가총액</div>
          <Input placeholder="시가총액" />
          <div className='input-label'>기타 정보</div>
          <TextArea
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="기타 정보"
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </div>
      </Modal>
      <div className='page-container'>
        <div className='page-title'>회사정보</div>
        <div className='page-top-menu-wrap'>
          <div className='page-filter-wrap'>
            <Input placeholder="회사 이름 검색" prefix={<BankOutlined />} style={{width: 300}} />
            <Button type="primary" icon={<SearchOutlined />}>
              검색
            </Button>
          </div>
          <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => setIsModalOpen(true)}>
            회사추가
          </Button>
        </div>
        <Table columns={columns} dataSource={data} bordered />
      </div>
    </div>
  );
};

export default CompanyInfoPage;