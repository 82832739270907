import { logoAxa, logoChubb, logoZurich, piInfinity, piInherit, piIpDiv, piNextAssign, piRecurWithdraw, piShield, pLabelHot, sfPromotion } from "./icon_route";

// * 송금시 증권번호와 성함(여권과 동일) 반드시 기입
// 납부 보험료 + Levy + USD$40 추가로 송금 (수취 은행 송금 수수료, 전신료 등의 추가 발생 수수료) // 초과 보험료는 고객님의 FPDA 예치금 계좌에 입금 됨
const accountInfo = {
  axa: {
    bankName: 'The Hong Kong and Shanghai Banking Corporation',
    bankAddress: 'HSBC Main Building, 1 Queen\'s Road Central, Hong Kong',
    bankAccountName: 'AXA China Region Insurance Co Ltd',
    bankCode: '004',
    bankAccount: [
      {addr: '391-91234158', curr: 'HKD'},
      {addr: '391-91234107', curr: 'USD'},
    ],
    swiftCode: 'CITIHKHX',
  },
  zurich: {
    bankName: 'Citibank, N.A. Hong Kong Branch',
    bankAddress: 'Champion Tower, Three Garden Road, Central, Hong Kong',
    bankAccountName: 'Zurich Insurance Life (Hong Kong) Limited',
    bankCode: '006',
    bankAccount: [
      {addr: '499-000966-006', curr: 'HKD'},
      {addr: '499-000966-201', curr: 'USD'},
    ],
    swiftCode: 'HSBCHKHHHKH',
  },
  generali: {
    bankName: 'Citibank, N.A. Hong Kong Branch',
    bankAddress: 'Citibank Tower, Citibank Plaza, 3 Garden Road, Central, Hong Kong',
    bankAccountName: 'Generali Life (Hong Kong) Limited',
    bankCode: '006',
    bankAccount: [
      {addr: '391-62520091', curr: 'HKD'},
      {addr: '391-62520113', curr: 'USD'},
    ],
    swiftCode: 'CITIHKHX',
  },
};

const products = [
  {
    labels: [pLabelHot],
    logo: logoZurich,
    name: 'Swiss Fortune Universal Life Plan',
    code: 'ZURICH_01',
    durations: [5, 10],
    simDurations: [5],
    allowMonthlyPaid: true,
    minForYear: 4800,
    minForMonth: 400,
    bankInfo: accountInfo.zurich,
    availableAge: '15일 ~ 75세',
    benefitTerm: '보험승낙일로부터 160년',
    launchingYear: '2023', // 추정
    homepageLink: 'https://lifeuat.zurich.com.hk/en/products/swiss-fortune-universal-life-plan/',
    fulfillment: 'https://www.zurich.com.hk/-/media/project/zwp/hongkong/docs/customer-services/life-policy-service/zlihk/important-information/zlihk-fulfillment-ratio-for-2023-shelved-products.pdf?rev=8f3126d1214f4a17976bf49fe67bedd9&hash=7A0538F6B1C2E48B4A196009988DDA87',
    company: 'Zurich Insurance Group',
    companyHomepageLabel: 'www.zurich.com',
    companyHomepage: 'https://www.zurich.com.hk/',
    companyInfo: '취리히 보험 그룹(Zurich)은 200개 이상의 국가 및 지역에서 개인과 기업에 서비스를 제공하는 선도적인 다계열 보험사입니다. 150년 전에 설립된 취리히는 보험을 변화시키고 있습니다. 보험 보호를 제공하는 것 외에도 취리히는 웰빙을 촉진하고 기후 회복력을 강화하는 것과 같은 예방 서비스를 점점 더 많이 제공하고 있습니다.',
    promotion: sfPromotion,
    recommends: [
      '스스로 노후 대비를 위한 자산 확보를 원하시는 분',
      '자녀의 대학 · 결혼 자금을 마련하고 싶으신 분',
    ],
    features: [
      {icon: piIpDiv, explain: '증권 분할 가능(6년차 부터 2개 이상)'},
      {icon: piRecurWithdraw, explain: '매월 정기 인출(납입완료 이후) 가능'},
      {icon: piInfinity, explain: '피보험자 무제한 변경 가능'},
      {icon: piNextAssign, explain: '후속 피보험자 지정 가능(2차년도 부터)'},
      {icon: piInherit, explain: '증여/상속(Legacy) 가능'},
      {icon: piShield, explain: '계약자 재해 사망 시 납입면제'},
    ],
    irrRange: '',
    irrTable: {
      pessimistic: [],
      basic: [],
      optimistic: [],
    },
    irrListLabel: {type: 'string', data: 'S&P500 수익률 연동'},
    avgFullfillmentRatio: '-',
    intro: [
      {class: 'm15 c3 light', content: 'Swiss Fortune Universal Life Plan은 S&P500지수 연동상품으로 연간 배당금을 아래와 같이 산정합니다.'},
      {class: '', content: ''},
      {class: 'r14', content: '1. 당해 S&P500 연간 수익률이 0 ~ 11.7%이면 S&P500 연간 수익률과 동일하게 배당'},
      {class: 'r14', content: '2. 당해 S&P500 연간 수익률이 11.7%를 초과하면 11.7% 배당'},
      {class: 'r14', content: '3. 당해 S&P500 연간 수익률이 마이너스면 손실없이 0% 배당'},
    ],
    marketCap: '119조원 (24.11.08)',
    companyStock: 'https://www.investing.com/equities/zurich-insurance-group-ltd',
    creditTable: [
      {c: 'S&P', g: 'AA'},
      {c: 'Moody\'s', g: 'Aa3'},
      {c: 'A.M.Best', g: 'A+'},
    ],
  },
  {
    logo: logoAxa,
    name: 'Wealth Advance Savings Series II - Ultimate',
    code: 'AXA_ULTIMATE',
    durations: [5, 10], // pdf
    simDurations: [5, 10],
    allowMonthlyPaid: false,
    minForYear: 15000, // pdf
    minForMonth: 0,
    bankInfo: accountInfo.axa,
    availableAge: '0세 ~ 60세',
    benefitTerm: '100세까지',
    launchingYear: '2018', // 추정
    homepageLink: 'https://www.axa.com.hk/en/wealth-advance-saving-series-ultimate',
    fulfillment: 'https://www.axa.com.hk/en/fulfilment-ratios-total-value-ratios-wealth-advance-savings-series-ii-ultimate#?Tab$ratios=tab-fulfilment-ratios-wealth-advance-savings-series-ii-ultimate',
    company: 'AXA',
    companyHomepageLabel: 'www.axa.com',
    companyHomepage: 'https://www.axa.com.hk/',
    companyInfo: 'AXA 그룹의 일원으로서 우리는 보험, 자산 관리 및 은퇴 계획 분야의 글로벌 리더가 되기 위한 승리 전략을 추진하는 일련의 정의된 가치와 헌신을 공유합니다. 19세기 초에 설립된 AXA 그룹은 전 세계 9,400만 명의 고객에게 51개 시장에서 147,000명의 직원이 서비스를 제공하고 있습니다. AXA 그룹의 등록 수익은 1,027억 유로(약 HKD8,719억)에 달했고, 관리 자산은 9,460억 유로(약 HKD8,0315억)에 달했습니다.',
    irrRange: '5.29% ~ 6.93%',
    irrTable: {
      pessimistic: [
        // {durationLabel: '10년', duration: 10, ratioLabel: '0.61%', ratio: 0.0061},
        {durationLabel: '20년', duration: 20, ratioLabel: '2.93%', ratio: 0.0293},
        {durationLabel: '30년', duration: 30, ratioLabel: '3.42%', ratio: 0.0342},
        {durationLabel: '70년', duration: 70, ratioLabel: '4.17%', ratio: 0.0417},
        {durationLabel: '100년', duration: 100, ratioLabel: '4.51%', ratio: 0.0451},
      ],
      basic: [
        // {durationLabel: '10년', duration: 10, ratioLabel: '2.87%', ratio: 0.0287},
        {durationLabel: '20년', duration: 20, ratioLabel: '5.29%', ratio: 0.0529},
        {durationLabel: '30년', duration: 30, ratioLabel: '5.90%', ratio: 0.0590},
        {durationLabel: '70년', duration: 70, ratioLabel: '6.66%', ratio: 0.0666},
        {durationLabel: '100년', duration: 100, ratioLabel: '6.93%', ratio: 0.0693},
      ],
      optimistic: [
        // {durationLabel: '10년', duration: 10, ratioLabel: '4.97%', ratio: 0.0497},
        {durationLabel: '20년', duration: 20, ratioLabel: '7.53%', ratio: 0.0753},
        {durationLabel: '30년', duration: 30, ratioLabel: '8.21%', ratio: 0.0821},
        {durationLabel: '70년', duration: 70, ratioLabel: '9.05%', ratio: 0.0905},
        {durationLabel: '100년', duration: 100, ratioLabel: '9.26%', ratio: 0.0926},
      ],
    },
    irrListLabel: {type: 'irr20', data: '5.29%~6.93%'},
    avgFullfillmentRatio: '99%',
    marketCap: '104조원 (24.11.08)',
    companyStock: 'https://www.investing.com/equities/axa',
    creditTable: [
      {c: 'S&P', g: 'AA-'},
      {c: 'Moody\'s', g: 'Aa3'},
      {c: 'Fitch', g: 'AA-'},
    ],
  },
  {
    logo: logoChubb,
    name: 'MyLegacy Insurance Plan III',
    code: 'CHUBB_MYLEGACY',
    durations: [2, 5, 8],
    simDurations: [2, 5],
    allowMonthlyPaid: false,
    minForYear: 5000,
    minForMonth: 0,
    bankInfo: accountInfo.generali,
    availableAge: '15일 ~ 75세',
    benefitTerm: '120세까지',
    launchingYear: '확인안됨', // 추정
    homepageLink: 'https://www.chubb.com/hk-en/personal/hrd.html',
    fulfillment: '',
    company: 'Chubb Life Insurance',
    companyHomepageLabel: 'www.chubb.com',
    companyHomepage: 'https://www.chubb.com/hk-en/',
    companyInfo: 'Chubb는 1882년 스위스에서 설립되었으며 2024년 기준 142년의 역사를 갖고 있습니다. 1984년도에 뉴욕 증권거래소에 상장했으며, 2022년에는 미국 상장 보험회사중에 가장 오래된 회사인 Cigna의 APAC Markets(한국, 대만, 뉴질랜드, 태국, 홍콩 및 인도네시아)를 인수했습니다. 2023년 기준 시가총액 전세계 TOP10 보험사입니다.',
    irrRange: '5.15% ~ 6.99%',
    irrListLabel: {type: 'irr20', data: '5.15%~6.99%'},
    irrTable: {
      pessimistic: [
        {durationLabel: '20년', duration: 20, ratioLabel: '2.75%', ratio: 0.0275},
        {durationLabel: '30년', duration: 30, ratioLabel: '3.41%', ratio: 0.0341},
        {durationLabel: '50년', duration: 50, ratioLabel: '4.32%', ratio: 0.0432},
        // {durationLabel: '100년', duration: 100, ratioLabel: '6.99%', ratio: 0.0699},
      ],
      basic: [
        {durationLabel: '20년', duration: 20, ratioLabel: '5.15%', ratio: 0.0515},
        {durationLabel: '30년', duration: 30, ratioLabel: '5.77%', ratio: 0.0577},
        {durationLabel: '50년', duration: 50, ratioLabel: '6.35%', ratio: 0.0635},
        {durationLabel: '100년', duration: 100, ratioLabel: '6.99%', ratio: 0.0699},
      ],
      optimistic: [

      ],
    },
    marketCap: '156조원 (24.11.08)',
    companyStock: 'https://www.investing.com/equities/chubb-corp',
    avgFullfillmentRatio: '88%',
    creditTable: [
      {c: 'S&P', g: 'AA'},
      {c: 'Moody\'s', g: 'Aa3'},
      {c: 'Fitch', g: 'AA'},
      {c: 'A.M.Best', g: 'A++'},
    ],
  }, 
  {
    logo: logoAxa,
    name: 'Wealth Ultra Savings Plan',
    code: 'AXA_ULTRA',
    durations: [8],
    simDurations: [8],
    allowMonthlyPaid: false,
    minForYear: 5000,
    minForMonth: 0,
    bankInfo: accountInfo.axa,
    availableAge: '0세 ~ 60세',
    benefitTerm: '138세까지',
    launchingYear: '2020', // 추정
    homepageLink: 'https://www.axa.com.hk/en/wealth-ultra-savings-plan',
    fulfillment: 'https://www.axa.com.hk/en/fulfilment-ratios-total-value-ratios-wealth-ultra-savings-plan',
    company: 'AXA',
    companyHomepageLabel: 'www.axa.com',
    companyHomepage: 'https://www.axa.com.hk/',
    companyInfo: 'AXA 그룹의 일원으로서 우리는 보험, 자산 관리 및 은퇴 계획 분야의 글로벌 리더가 되기 위한 승리 전략을 추진하는 일련의 정의된 가치와 헌신을 공유합니다. 19세기 초에 설립된 AXA 그룹은 전 세계 9,400만 명의 고객에게 51개 시장에서 147,000명의 직원이 서비스를 제공하고 있습니다. AXA 그룹의 등록 수익은 1,027억 유로(약 HKD8,719억)에 달했고, 관리 자산은 9,460억 유로(약 HKD8,0315억)에 달했습니다.',
    irrRange: '5.14% ~ 6.98%',
    irrListLabel: {type: 'irr20', data: '5.14%~6.98%'},
    irrTable: {
      pessimistic: [],
      basic: [
        {durationLabel: '20년', duration: 20, ratioLabel: '5.14%', ratio: 0.0514},
        {durationLabel: '30년', duration: 30, ratioLabel: '5.99%', ratio: 0.0599},
        {durationLabel: '50년', duration: 50, ratioLabel: '6.31%', ratio: 0.0631},
        {durationLabel: '93년', duration: 93, ratioLabel: '6.98%', ratio: 0.0698},
      ],
      optimistic: [],
    },
    avgFullfillmentRatio: '99%',
    marketCap: '104조원 (24.11.08)',
    companyStock: 'https://www.investing.com/equities/axa',
    creditTable: [
      {c: 'S&P', g: 'AA-'},
      {c: 'Moody\'s', g: 'Aa3'},
      {c: 'Fitch', g: 'AA-'},
    ],
  },
  // {
  //   logo: logoGenerali,
  //   name: 'LionTycoon Beyond 2',
  //   code: 'GENERALI_LT',
  //   durations: [2],
  //   allowMonthlyPaid: false,
  //   minForYear: 10000,
  //   minForMonth: 0,
  //   bankInfo: accountInfo.generali,
  //   availableAge: '15일 ~ 75세',
  //   benefitTerm: '138세까지',
  //   launchingYear: '확인안됨', // 추정
  //   homepageLink: 'https://www.generali.com.hk/EN_US/savings-and-retirement/liontycoon-beyond-2',
  //   fulfillment: '',
  //   company: 'GENERALI',
  //   companyHomepage: 'https://www.generali.com.hk/',
  //   companyInfo: '1981년 Assicurazioni Generali S.p.A는 처음으로 홍콩에서 공인 보험사로 등록되었으며, 2016년 Generali Life(Hong Kong) Limited를 통해 생명 보험 부문으로 사업을 확장했습니다. 현지 지식과 Generali Group의 글로벌 전문 지식을 결합하여 고객의 요구 사항을 충족하는 독특하고 혁신적인 생명 보험, 일반 보험, 전문 보험 및 직원 복리후생 솔루션을 개발합니다.',
  //   irrRange: '5.4%',
  //   irrListLabel: {type: 'irr20', data: '5.4%'},
  //   avgFullfillmentRatio: '100%',
  // },
];

export default products;
