import { getApp } from "firebase/app";
import { getVertexAI, getGenerativeModel } from "firebase/vertexai";

async function fileToGenerativePart(file: any) {
  const base64EncodedDataPromise = new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve((reader.result! as any).split(',')[1]);
    reader.readAsDataURL(file);
  });
  return {
    inlineData: { data: await base64EncodedDataPromise, mimeType: file.type },
  };
}


const checkProposalData = async (code: string, file: any) => {
  const vertexAI = getVertexAI(getApp());
  const model = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash-002" });

  const v = await fileToGenerativePart(file);

  let prompt = '';
  if (code === 'ZURICH_01') {
    prompt = 'basic plan Current Assumed basis account value 연차별로 json으로 뽑아줘 포맷은 이렇게 "year: 연도, total: total value"'
  } else {
    prompt = 'basic plan surrender value 연차별로 json으로 뽑아줘 포맷은 이렇게 "year: 연도, total: total value"'
  }

  const result = await model.generateContentStream([prompt, v as any]);

  let resString = '';
  for await (const chunk of result.stream) {
    const chunkText = chunk.text();
    resString += chunkText
  }

  return JSON.parse(resString.replaceAll('```json', '').replaceAll('```', ''));
};

export default checkProposalData;
