import { getApp } from "firebase/app";
import { doc, getFirestore, updateDoc } from "firebase/firestore";

const updateContractSubstate = async (taskId: string, step: string, subStep: number, updatedValue: any) => {
  await updateDoc(doc(getFirestore(getApp()), 'kr_user_tasks', taskId), {
    step,
    subStep,
    ...updatedValue,
  });
};

export default updateContractSubstate;
