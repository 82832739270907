import { BankOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Collapse, Image, Input, Modal, Popconfirm, PopconfirmProps, Radio, RadioChangeEvent, Steps, Table, TableProps, message } from 'antd';
import React, { useState } from 'react';

const exchangeRate = {won: 1378.85, dollar: 1, yuan: 7.24};

interface DataType {
  key: string;
  productName: string;
  userName: string;
  subCode: string;
  subDate: string;
}

interface ProductInfoType {
  key: string;

  productType: string;
  releaseDate: Number;
  transfer: boolean;
  infoBySellerURL: string;
  paymentPeriod: number;
  monthlyPaymemt: number;
  cancelPolicyURL: string;
  annualInterestRate: number;
  maturityReturn: number;
  paymentDetailURL: string;
  coverage: Array<{name: string, amount: number}>;
}

interface CompanyInfoType {
  key: string;

  companyName: string;
  companyURL: string;
  companyCountry: string;
  marketCapitalization: number;
  companyInfo: string;
}

interface SubscriptionInfoType{
  key: string;

  payment: Array<{amount: number, date: Number}>;
  totalCashValue: number;
  cashValue: number;
  reversionaryBonus: number;
  terminalBonus: number;
  averageFulfillment: number;
  fulfillment: Array<{rate: number, date: Number}>;
}

interface ContractInfoType{
  key: string;

  name: string;
  mobile: string;
  registrationNumb: string;
  email: string;
  dependents: number;
  address: string;

  companyName: string;
  sector: string;
  companyAddress: string;
  companyHomepage: string;
  job: string;
  position: string;
  responsibilities: string;
  periodWorked: number;

  monthlyIncome: number;
  monthlySaving: number;
  currentAssets: number;

  insuredName: string;
  insuredRegistrationNumb: string;

  paymentPeriod: number;
  paymentMethod: string;
  cardType: string;
  cardNumb: string;
  cvc: string;
  expirationDate: Number;
  cardName: string;
  callAvailableHours: string;

  beneficiaryName: string;
  beneficiaryRegistrationNumb: string;
  beneficiaryMobile: string;
  relationship: string;

  passportCopy: string;
  registrationCopy: string;
  remittanceReceipt: string;
  useAgreement: string;
}

const confirm: PopconfirmProps['onConfirm'] = (e) => {
  message.success('회사 정보를 삭제했습니다');
};

const cancel: PopconfirmProps['onCancel'] = (e) => {
  message.error('취소되었습니다');
};

const SubscriptionInfoPage = () => {

  const columns: TableProps<DataType>['columns'] = [
    {
      title: '가입번호',
      dataIndex: 'subCode',
      key: 'subCode',
      render: (text) => <a onClick={showModal}>{text}</a>,
    },
    {
      title: '상품명',
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: '유저명',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: '가입일',
      dataIndex: 'subDate',
      key: 'subDate',
      width: 200,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Popconfirm
          title="회사정보 삭제"
          description="해당 회사의 정보를 삭제하시겠습니까?"
          onConfirm={confirm}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <a>Delete</a>
        </Popconfirm>
      ),
      width: 120,
      align: 'center'
    },
  ];

  const data: DataType[] = [
    {
      key: '1',
      subCode: '15a2d68f',
      productName: 'Wealth Advance Savings Series II - Ultimate',
      userName: '홍길동',
      subDate: '2024-04-29 14:00:00',
    },
    {
      key: '2',
      subCode: '15a2d682',
      productName: 'Wealth Advance Savings Series II - Ultimate',
      userName: '홍길동',
      subDate: '2024-04-29 14:00:00',
    },
    {
      key: '3',
      subCode: '15a2d683',
      productName: 'Wealth Advance Savings Series II - Ultimate',
      userName: '홍길동',
      subDate: '2024-04-29 14:00:00',
    },
  ];

  const productInfoColumns: TableProps<ProductInfoType>['columns'] = [
    {
      title: '상품 분류',
      dataIndex: 'productType',
      key: 'productType',
    },
    {
      title: '출시일',
      dataIndex: 'releaseDate',
      key: 'releaseDate',
      render: (number) => <>{`${new Date(number).toLocaleString("ko-KR", {timeZone: "Asia/Seoul"})}`}</>
    },
    {
      title: '양도 여부',
      dataIndex: 'transfer',
      key: 'transfer',
      render: (bool) => <span>{bool ? '가능' : '불가능'}</span>,
    },
    {
      title: '판매사 제공 정보',
      dataIndex: 'infoBySellerURL',
      key: 'infoBySellerURL',
      width: 160,
      render: (text) => <a onClick={() => window.open(text)}>바로가기</a>,
    },
    {
      title: '납부 기간',
      dataIndex: 'paymentPeriod',
      key: 'paymentPeriod',
      render: (month) => <>{month} 개월</>
    },
    {
      title: '월 납입액',
      dataIndex: 'monthlyPaymemt',
      key: 'monthlyPaymemt',
      render: (amount) => <>{returnBase()}{exchange(amount)?.toLocaleString()}</>
    },
    {
      title: '해지 규정',
      dataIndex: 'cancelPolicyURL',
      key: 'cancelPolicyURL',
      width: 100,
      render: (text) => <a onClick={() => window.open(text)}>바로가기</a>,
    },
    {
      title: '연이율',
      dataIndex: 'annualInterestRate',
      key: 'annualInterestRate',
      width: 100,
      render: (amount) => <>{amount} %</>
    },
    {
      title: '만기 수익',
      dataIndex: 'maturityReturn',
      key: 'maturityReturn',
      render: (amount) => <>{amount} %</>
    },
    {
      title: '납입 및 수익 상세',
      dataIndex: 'paymentDetailURL',
      key: 'paymentDetailURL',
      render: (text) => <a onClick={() => window.open(text)}>바로가기</a>,
    },
    {
      title: '보장정보',
      dataIndex: 'coverage',
      key: 'coverage',
      width: 200,
      render: (array) => <span>{array[0].name}</span>,
    }
  ];

  const productInfoData: ProductInfoType[] = [
    {
      key: '1',

      productType: '배당 저축 보험',
      releaseDate: (new Date(2024, 4, 30)).getTime(),
      transfer: true,
      infoBySellerURL: 'https://www.naver.com',
      paymentPeriod: 60,
      monthlyPaymemt: 1000,
      cancelPolicyURL: 'https://www.naver.com',
      annualInterestRate: 7,
      maturityReturn: 150,
      paymentDetailURL: 'https://www.naver.com',
      coverage: [{name: '암 수술비', amount: 100000000}],
    },
  ];

  const companyInfoColumns: TableProps<CompanyInfoType>['columns'] = [
    {
      title: '회사명',
      dataIndex: 'companyName',
      key: 'companyName',
      width: 200,
    },
    {
      title: '홈페이지',
      dataIndex: 'companyURL',
      key: 'companyURL',
      render: (text) => <a onClick={() => window.open(text)}>바로가기</a>,
      width: 120,
      align: 'center',
    },
    {
      title: '국가',
      dataIndex: 'companyCountry',
      key: 'companyCountry',
      width: 200,
    },
    {
      title: '시가총액',
      dataIndex: 'marketCapitalization',
      key: 'marketCapitalization',
      width: 200,
      render: (amount) => <>{returnBase()}{exchange(amount)?.toLocaleString()}</>
    },
    {
      title: '기타정보',
      dataIndex: 'companyInfo',
      key: 'companyInfo',
    },
  ];

  const companyInfoData: CompanyInfoType[] = [
    {
      key: '1',

      companyName: 'AXA',
      companyURL: 'https://www.axa.com',
      companyCountry: '프랑스',
      marketCapitalization: 83194208200,
      companyInfo: 'AXA는 프랑스를 본거지로 한 글로벌 보험 및 자산 관리 회사입니다. 1817년에 설립되었으며, 현재는 세계적인 보험 및 금융 서비스 제공업체 중 하나로 손꼽힙니다. AXA는 생명보험, 재무 계획, 재무 상품, 재무 컨설팅 등의 다양한 금융 서비스를 제공하고 있습니다. 또한 자산 운용 분야에서도 활동하고 있으며, 고객들에게 투자 관리 서비스를 제공합니다. 현재 AXA는 전 세계적으로 다양한 국가와 지역에서 사업을 전개하고 있습니다.',
    },
  ];

  const subscriptionInfoColumns: TableProps<SubscriptionInfoType>['columns'] = [
    {
      title: '납입',
      dataIndex: 'payment',
      key: 'payment',
      render: (array) => <Collapse
        size="small"
        items={[{
          key: '1',
          label: `납입 총 ${array.length}회 ${returnBase()}${exchange(array.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.amount, 0))?.toLocaleString()}`,
          children: <p>{array.map((item: any) => {
            return <div><strong>{`${returnBase()}${exchange(item.amount)?.toLocaleString()}`}</strong>{`, ${new Date(item.date).toLocaleString("ko-KR", {timeZone: "Asia/Seoul"})}`}</div>
          })}</p>
        }]}
      />,
      align: 'center',
    },
    {
      title: 'totalCashValue',
      dataIndex: 'totalCashValue',
      key: 'totalCashValue',
      width: 200,
      render: (amount) => <>{returnBase()}{exchange(amount)?.toLocaleString()}</>
    },
    {
      title: 'cashValue',
      dataIndex: 'cashValue',
      key: 'cashValue',
      width: 200,
      render: (amount) => <>{returnBase()}{exchange(amount)?.toLocaleString()}</>
    },
    {
      title: 'reversionaryBonus',
      dataIndex: 'reversionaryBonus',
      key: 'reversionaryBonus',
      width: 200,
      render: (amount) => <>{returnBase()}{exchange(amount)?.toLocaleString()}</>
    },
    {
      title: 'terminalBonus',
      dataIndex: 'terminalBonus',
      key: 'terminalBonus',
      width: 200,
      render: (amount) => <>{returnBase()}{exchange(amount)?.toLocaleString()}</>
    },
    {
      title: '이행률',
      dataIndex: 'fulfillment',
      key: 'fulfillment',
      render: (array, record, index) => <Collapse
        size="small"
        items={[{
          key: '1',
          label: `이행 총 ${array.length}회 평균 ${subscriptionInfoData[index].fulfillment.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.rate, 0) / subscriptionInfoData[index].fulfillment.length} %`,
          children: <p>{array.map((item: any) => {
            return <div><strong>{`${item.rate}%`}</strong>{`, ${new Date(item.rate).toLocaleString("ko-KR", {timeZone: "Asia/Seoul"})}`}</div>
          })}</p>
        }]}
      />,
      align: 'center',
      width: 400,
    },
  ];

  const subscriptionInfoData: SubscriptionInfoType[] = [
    {
      key: '1',

      payment: [{amount: 1000, date: (new Date(2024, 4, 30)).getTime()},{amount: 1000, date: (new Date(2024, 3, 30)).getTime()}],
      totalCashValue: 250000,
      cashValue: 50000,
      reversionaryBonus: 100000,
      terminalBonus: 100000,
      averageFulfillment: 60,
      fulfillment: [{rate: 50, date: (new Date(2024, 4, 30)).getTime()},{rate: 60, date: (new Date(2024, 3, 30)).getTime()}],
    },
  ];

  const contractData: ContractInfoType[] = [
    {
      key: '1',

      name: '홍길동',
      mobile: '01012341234',
      registrationNumb: '9000001000000',
      email: 'honggil@blackticket.com',
      dependents: 3,
      address: '대구시 북구 복현동 123',

      companyName: '골든웨이브',
      sector: '업종',
      companyAddress: '대구시 북구 복현동 123',
      companyHomepage: 'https://www.goldenwayve.com',
      job: '회사원',
      position: '신입',
      responsibilities: 'design',
      periodWorked: 60,
      
      monthlyIncome: 1000,
      monthlySaving: 50,
      currentAssets: 1000,

      insuredName: 'Hong Gil Dong',
      insuredRegistrationNumb: '9000001000000',

      paymentPeriod: 1,
      paymentMethod: 'card',
      cardType: 'visa',
      cardNumb: '0000000000000000',
      cvc: '000',
      expirationDate: (new Date(2024, 8, 30)).getTime(),
      cardName: 'honggildong',
      callAvailableHours: '09:00 ~ 18:00',

      beneficiaryName: 'Hong Gil Dong',
      beneficiaryRegistrationNumb: '9000000000000',
      beneficiaryMobile: '01000000000',
      relationship: '친구',

      passportCopy: '/img/dummy.png',
      registrationCopy: '/img/dummy.png',
      remittanceReceipt: '/img/dummy.png',
      useAgreement: '/img/dummy.png',
    },
  ];

  const contractColumns1: TableProps<ContractInfoType>['columns'] = [
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: '전화번호',
      dataIndex: 'mobile',
      key: 'mobile',
      align: 'center',
    },
    {
      title: '주민등록번호',
      dataIndex: 'registrationNumb',
      key: 'registrationNumb',
      align: 'center',
    },
    {
      title: '이메일',
      dataIndex: 'email',
      key: 'email',
      align: 'center',
    },
    {
      title: '부양 가족수',
      dataIndex: 'dependents',
      key: 'dependents',
      align: 'center',
    },
    {
      title: '우편물 수령 주소',
      dataIndex: 'address',
      key: 'address',
      align: 'center',
    },
  ];

  const contractColumns2: TableProps<ContractInfoType>['columns'] = [
    {
      title: '회사명',
      dataIndex: 'companyName',
      key: 'companyName',
      align: 'center',
    },
    {
      title: '업종',
      dataIndex: 'sector',
      key: 'sector',
      align: 'center',
    },
    {
      title: '회사 주소',
      dataIndex: 'companyAddress',
      key: 'companyAddress',
      align: 'center',
    },
    {
      title: '회사 홈페이지',
      dataIndex: 'companyHomepage',
      key: 'companyHomepage',
      align: 'center',
      render: (url) => <a onClick={() => window.open(url)}>{url}</a>
    },
    {
      title: '직업',
      dataIndex: 'job',
      key: 'job',
      align: 'center',
    },
    {
      title: '직책',
      dataIndex: 'position',
      key: 'position',
      align: 'center',
    },
    {
      title: '담당 업무',
      dataIndex: 'responsibilities',
      key: 'responsibilities',
      align: 'center',
    },
    {
      title: '근무 기간',
      dataIndex: 'periodWorked',
      key: 'periodWorked',
      align: 'center',
      render: (period) => <>{period}개월</>
    },
  ];

  const contractColumns3: TableProps<ContractInfoType>['columns'] = [
    {
      title: '월 수입금액 (USD)',
      dataIndex: 'monthlyIncome',
      key: 'monthlyIncome',
      align: 'center',
      render: (amount) => <>${amount}</>
    },
    {
      title: '월 지출금액(USD)',
      dataIndex: 'monthlySaving',
      key: 'monthlySaving',
      align: 'center',
      render: (amount) => <>${amount}</>
    },
    {
      title: '유동자산(USD)',
      dataIndex: 'currentAssets',
      key: 'currentAssets',
      align: 'center',
      render: (amount) => <>${amount}</>
    },
  ];

  const contractColumns4: TableProps<ContractInfoType>['columns'] = [
    {
      title: '피보험자 영문 이름(여권과 동일)',
      dataIndex: 'insuredName',
      key: 'insuredName',
      align: 'center',
    },
    {
      title: '피보험자 주민등록번호',
      dataIndex: 'insuredRegistrationNumb',
      key: 'insuredRegistrationNumb',
      align: 'center',
    },
  ];

  const contractColumns5: TableProps<ContractInfoType>['columns'] = [
    {
      title: '납입주기',
      dataIndex: 'paymentPeriod',
      key: 'paymentPeriod',
      align: 'center',
      render: (period) => <>{period}개월</>
    },
    {
      title: '납입방법',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      align: 'center',
    },
    {
      title: '카드종류',
      dataIndex: 'cardType',
      key: 'cardType',
      align: 'center',
    },
    {
      title: '카드번호',
      dataIndex: 'cardNumb',
      key: 'cardNumb',
      align: 'center',
    },
    {
      title: 'CVC',
      dataIndex: 'cvc',
      key: 'cvc',
      align: 'center',
    },
    {
      title: '유효기간',
      dataIndex: 'expirationDate',
      key: 'expirationDate',
      align: 'center',
      render: (date) => <>{`${new Date(date).toLocaleString("ko-KR", {timeZone: "Asia/Seoul"})}`}</>
    },
    {
      title: '카드 영문이름',
      dataIndex: 'cardName',
      key: 'cardName',
      align: 'center',
    },
    {
      title: '전화 안내 가능시간',
      dataIndex: 'callAvailableHours',
      key: 'callAvailableHours',
      align: 'center',
    },
  ];

  const contractColumns6: TableProps<ContractInfoType>['columns'] = [
    {
      title: '피보험자 영문 이름(여권과 동일)',
      dataIndex: 'beneficiaryName',
      key: 'beneficiaryName',
      align: 'center',
    },
    {
      title: '피보험자 주민등록번호',
      dataIndex: 'beneficiaryRegistrationNumb',
      key: 'beneficiaryRegistrationNumb',
      align: 'center',
    },
    {
      title: '피보험자 영문 이름(여권과 동일)',
      dataIndex: 'beneficiaryMobile',
      key: 'beneficiaryMobile',
      align: 'center',
    },
    {
      title: '피보험자 주민등록번호',
      dataIndex: 'relationship',
      key: 'relationship',
      align: 'center',
    },
  ];

  const contractColumns7: TableProps<ContractInfoType>['columns'] = [
    {
      title: '피보험자 영문 이름(여권과 동일)',
      dataIndex: 'passportCopy',
      key: 'passportCopy',
      align: 'center',
      render: (url) => <Image
          width={200}
          src={url}
        />
    },
    {
      title: '피보험자 주민등록번호',
      dataIndex: 'registrationCopy',
      key: 'registrationCopy',
      align: 'center',
      render: (url) => <Image
          width={200}
          src={url}
        />
    },
    {
      title: '피보험자 영문 이름(여권과 동일)',
      dataIndex: 'remittanceReceipt',
      key: 'remittanceReceipt',
      align: 'center',
      render: (url) => <Image
          width={200}
          src={url}
        />
    },
    {
      title: '피보험자 주민등록번호',
      dataIndex: 'useAgreement',
      key: 'useAgreement',
      align: 'center',
      render: (url) => <Image
          width={200}
          src={url}
        />
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      setIsModalOpen(false);
    }, 2000);

  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const exchangeOptions = [
    { label: '₩', value: 'won' },
    { label: '$', value: 'dollar' },
    { label: '¥', value: 'yuan', },
  ];

  const [exchangeBase, setExchangeBase] = useState('won');

  const exchange = (amount: number) => {
    if (exchangeBase === 'won') {
      return amount * exchangeRate.won;
    } else if (exchangeBase === 'dollar') {
      return amount;
    } else if (exchangeBase === 'yuan') {
      return amount * exchangeRate.yuan;
    }
  };

  const changeBase = ({ target: { value } }: RadioChangeEvent) => {
    setExchangeBase(value);
  };

  const returnBase = () => {
    if (exchangeBase === 'won') {
      return '₩';
    } else if (exchangeBase === 'dollar') {
      return '$'
    } else if (exchangeBase === 'yuan') {
      return '¥'
    }
  };

  return (
    <div className='page-wrap'>
      <Modal
        title="상품 가입번호: 15a2d68f"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button type="primary" size='large' danger loading={loading} onClick={handleOk}>
            심사거절
          </Button>,
          <Button key="back" size='large' onClick={handleCancel}>
            취소
          </Button>,
          <Button type="primary" size='large' loading={loading} onClick={handleOk}>
            심사완료
          </Button>
        ]}
        width={'90%'}
      >
        <div className='modal-body'>
          <div className='modal-section-title'>계약 상태</div>
          <div className='subscription-step-wrap'>
            <Steps
              current={0}
              // status="error"
              items={[
                {
                  title: '가입 심사',
                },
                {
                  title: '가입완료',
                },
              ]}
            />
          </div>
          <div className='subscription-step-wrap'>
            <Steps
              current={1}
              // status="error"
              items={[
                {
                  title: '가입 심사',
                },
                {
                  title: '가입완료',
                },
              ]}
            />
          </div>
          <div className='subscription-step-wrap'>
            <Steps
              current={1}
              status="error"
              items={[
                {
                  title: '가입 심사',
                },
                {
                  title: '거절',
                },
              ]}
            />
          </div>
          <div className='modal-section-title'>상품 정보</div>
          <div className='input-label'>화폐단위</div>
          <Radio.Group options={exchangeOptions} onChange={changeBase} value={exchangeBase} optionType="button" />
          <div className='input-label'>상품명</div>
          <div className='modal-text'>Wealth Advance Savings Series II - Ultimate</div>
          <div className='input-label'>상품 정보</div>
          <Table columns={productInfoColumns} dataSource={productInfoData} bordered style={{width: '100%'}} pagination={false} />
          <div className='input-label'>회사 정보</div>
          <Table columns={companyInfoColumns} dataSource={companyInfoData} bordered style={{width: '100%'}} pagination={false} />
          <div className='modal-section-title'>이행 및 환급 정보</div>
          <div className='input-label'>납입 정보</div>
          <Table columns={subscriptionInfoColumns} dataSource={subscriptionInfoData} bordered style={{width: '100%'}} pagination={false} />
          <div className='modal-section-title'>계약 정보</div>
          <div className='input-label'>계약 정보(기본 정보)</div>
          <Table columns={contractColumns1} dataSource={contractData} bordered style={{width: '100%'}} pagination={false} />
          <div className='input-label'>계약 정보(직장 정보)</div>
          <Table columns={contractColumns2} dataSource={contractData} bordered style={{width: '100%'}} pagination={false} />
          <div className='input-label'>계약 정보(재정 정보)</div>
          <Table columns={contractColumns3} dataSource={contractData} bordered style={{width: '100%'}} pagination={false} />
          <div className='input-label'>계약 정보(피보험자 정보)</div>
          <Table columns={contractColumns4} dataSource={contractData} bordered style={{width: '100%'}} pagination={false} />
          <div className='input-label'>계약정보(계약 내용)</div>
          <Table columns={contractColumns5} dataSource={contractData} bordered style={{width: '100%'}} pagination={false} />
          <div className='input-label'>계약정보(수익자 정보)</div>
          <Table columns={contractColumns6} dataSource={contractData} bordered style={{width: '100%'}} pagination={false} />
          <div className='input-label'>계약정보(제출서류)</div>
          <Table columns={contractColumns7} dataSource={contractData} bordered style={{width: '100%'}} pagination={false} />
        </div>
      </Modal>
      <div className='page-container'>
        <div className='page-title'>상품 가입정보</div>
        <div className='page-filter-wrap'>
          <Input placeholder="회사 이름 검색" prefix={<BankOutlined />} style={{width: 300}} />
          <Button type="primary" icon={<SearchOutlined />}>
            검색
          </Button>
        </div>
        <Table columns={columns} dataSource={data} bordered />
      </div>
    </div>
  );
};

export default SubscriptionInfoPage;