import { BankOutlined, FileSearchOutlined, LogoutOutlined, SolutionOutlined, TeamOutlined } from '@ant-design/icons';
import { Button, Menu, MenuProps, MenuTheme, Modal, Switch } from 'antd';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import routes from '../configs/routes';

interface Props{
  selectedKey: string;
  setSelectedKey: Dispatch<SetStateAction<string>>;
  setIsLogined: Dispatch<SetStateAction<boolean>>
}

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem('회사정보', '1', <BankOutlined />),
  getItem('상품정보', '2', <FileSearchOutlined />),
  getItem('상품 가입정보', '3', <SolutionOutlined />),
  getItem('영업사원 관리', '4', <TeamOutlined />),
];

const SideNav:React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const onClick: MenuProps['onClick'] = (e) => {
    props.setSelectedKey(e.key);
    if (e.key === '1') {
      navigate(routes.companyInfo);
    } else if (e.key === '2') {
      navigate(routes.productInfo);
    } else if (e.key === '3') {
      navigate(routes.subscriptionInfo);
    } else if (e.key === '4') {
      navigate(routes.salesInfo);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    props.setIsLogined(false);
    navigate(routes.default);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={`side-nav-container`}>
      <Modal
        title="로그아웃"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            취소
          </Button>,
          <Button key="submit" type="primary" danger onClick={handleOk}>
            로그아웃
          </Button>,]}
      >
        <p>로그아웃 하시겠습니까?</p>
      </Modal>
      <div>
        <div className='side-nav-logo-wrap'>
          <img src='/img/logo.png' alt='logo' />
        </div>
        <Menu
          selectedKeys={[`${props.selectedKey}`]}
          mode="inline"
          items={items}
          style={{borderRight: 'none'}}
          onClick={onClick}
        />
      </div>
      <Button
        onClick={() => showModal()}
        size={'large'} style={{margin: 4, padding: '0 24px', width: '100%', textAlign: 'left'}}
        type="text"
        danger
        icon={<LogoutOutlined />}
      >
        로그아웃
      </Button>
    </div>
  );
};

export default SideNav;