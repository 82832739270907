import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import routes from './configs/routes';
import SideNav from './components/side_nav';
import './styles/common.scss';
import CompanyInfoPage from './pages/company_info';
import ProductInfoPage from './pages/proudct_info';
import SubscriptionInfoPage from './pages/subscription_info';
import LoginPage from './pages/login';
import SalesInfoPage from './pages/sales_info';
import IFAPage from './pages/ifa';
import initFirebase from './utils/firebase/init_sdk';

import {getApp} from 'firebase/app';
import {getAuth, signOut} from 'firebase/auth';
import ReactLoading from 'react-loading';

const App = () => {
  const [menu, setMenu] = useState('1');

  const [userType, setUserType] = useState('ifa');

  const [initLoading, setInitLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    initFirebase();

    const app = getApp();
    const auth = getAuth(app);

    auth.onAuthStateChanged(async (user) => {
      if (user === null) {
        setIsLoggedIn(false);
        setInitLoading(false);
      } else {
        const {claims} = await user.getIdTokenResult();

        if (claims.authType === 'ifa' || claims.authType === 'admin') {
          setIsLoggedIn(true);
          setInitLoading(false);
        } else {
          signOut(auth);
        }
      }
    });
  }, []);

  if (initLoading) {
    return (
      <div style={{width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '120px'}}>
        <ReactLoading width={120} type="balls" color='black'/>
      </div>
    )
  }

  return (
    <BrowserRouter>
      <div className={`frame-wrap`}>
        {
          isLoggedIn && userType !== 'ifa' ? 
          <SideNav selectedKey={menu} setSelectedKey={setMenu} setIsLogined={setIsLoggedIn} /> : null
        }
        <div className={`frame ${userType === 'ifa' ? 'ifa' : ''}`}>
          {
            isLoggedIn ? (
              <Routes>
                <Route path={routes.companyInfo} element={<CompanyInfoPage />} />
                <Route path={routes.productInfo} element={<ProductInfoPage />} />
                <Route path={routes.subscriptionInfo} element={<SubscriptionInfoPage />} />
                <Route path={routes.salesInfo} element={<SalesInfoPage />} />
                <Route path="*" element={<IFAPage />} />
              </Routes>
            ) : (
              <Routes>
                <Route path="*" element={<LoginPage setIsLogined={setIsLoggedIn} userType={userType} />} />
              </Routes>
            )
          }
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
